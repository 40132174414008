import { graphql } from "gatsby"
import Layout, { IMetadata } from "../../../components/Layout"
import React from "react"
import Section from "../../../components/ui/Section/Section";
import IconGraphicDesignSvg from "../../../assets/gestalten.svg";
import IProdukt from "../../../interfaces/IProdukt";
import Blocks from "../../../components/blocks/Blocks";
import useGdprIsAllowed from "../../../components/Gdpr/useGdprIsAllowed";
import Hero from "../../../components/ui/Hero/Hero";
import { CartContext } from "../../../components/Cart/CartContext";
import ButtonAction from "../../../components/ui/Buttons/ButtonAction";

export default ({ data, location }) => {
    const product: IProdukt = data.produkteHJson;
    const {dispatch} = React.useContext(CartContext);

    // Tracking Product Page View to Matomo
    const trackingAllowed = useGdprIsAllowed("Analyse");
    React.useEffect(() => {
            if (trackingAllowed && _paq) {
                _paq.push(['setEcommerceView',
                    product.url, // (required) SKU: Product unique identifier
                    product.name, // (optional) Product name
                    product.produktTyp, // (optional) Product category, or array of up to 5 categories
                ]);

                // _paq.push(['trackPageView']);
            } 
    });


    const crumbs = [
        { label: "Home", url: "" },
        { label: "Produkte", url: "produkte" },
        { label: "Zubehör", url: "zubehoer"},
        { label: product.name, url: product.url.split("/")[1]},
    ];

    // END BREADCRUMB

    // Create metadata object
    const metaData: IMetadata = {};
   if (product.meta) { 
       if (product.meta.description) {
            metaData.description = product.meta.description;
        }
        if (product.meta.title) {
            metaData.title = product.meta.title;
        }
        if (product.meta.ogImage) {
            metaData.ogImage = product.meta.ogImage.childImageSharp.gatsbyImageData.src;
        }
        if (product.meta.ogTitle) {
            metaData.ogTitle = product.meta.ogTitle;
        }
        if (product.meta.ogDescription) {
            metaData.ogDescription = product.meta.ogDescription;
        }
    }

    const addToCart = () => {
        dispatch({type: "ADD_PRODUCT", product, configuration: {}, qty: 1 });
        dispatch({type: "OPEN_MINI_CART"});
    }

    return (
        <Layout crumbs={crumbs} location={location} metadatas={metaData} hasWave={false}>

            <Hero
                color="light"
            >
                <div className="columns is-vcentered">
                    <div className="column is-offset-1-desktop is-7-desktop">
                        <h1 className="title">Gestaltung Anfragen</h1>
                        <p className="subtitle">
                            Designerstellung oder Datenaufbereitung
                        </p>
                    </div>
                    <div className="column">
                        <img alt="Grafische gestaltung" src={IconGraphicDesignSvg} />
                    </div>
                </div>
            </Hero>

            <Section size="extraSmall">
                <div className="content" dangerouslySetInnerHTML={{__html: product.produktText}}>

                </div>
                <ButtonAction onClick={addToCart}>Gestaltung Anfragen</ButtonAction>
            </Section>

            {product.blocks && <Blocks blocks={product.blocks} />}
            
        </Layout>
    )
}

export const productQuery = graphql`{
  produkteHJson(url: {eq: "zubehoer/grafik-design"}) {
    name
    meta {
      title
      description
      ogTitle
      ogImage {
        childImageSharp {
          gatsbyImageData(width: 300, height: 300, layout: FIXED)
        }
      }
      ogDescription
    }
    bewertung {
      stars
      typ
    }
    vorteile
    productWidgetKey
    filters {
      typ
      wert
      filter
      widget
      widgetType
      defaultValue
    }
    infos {
      beschriftung
      wert
    }
    kurzbeschreibung
    produktText
    produktTyp
    url
    hauptbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    listenbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    bilder {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    blocks {
      ...BlocksFragment
    }
  }
}
`
